
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import settingsApi from "@/core/services/Settings";
import entitiesApi from "@/core/services/Entities";
import { useStore } from "vuex";
import XLSX from "xlsx";
import buttonHandle from "@/shared.js";
import { useI18n } from "vue-i18n";

export default defineComponent({
    name: "administration-settings",
    components: {
        ErrorMessage,
        Field,
        Form,
    },
    data: function () {
        return {
            groupReportFile: "" as string | ArrayBuffer,
            entitiesFile: [],
            avatar: "/media/avatars/blank.png",
        };
    },
    methods: {
        handleFileGroup(e) {
            const selectedFile = e.target.files[0];
            const reader = new FileReader();
            reader.onload = (ev) => {
                if (ev.target && ev.target.result) {
                    this.groupReportFile = ev.target.result;
                }
            };
            reader.readAsDataURL(selectedFile);
        },
        handleFile(e) {
            const selectedFIle = e.target.files[0];
            const reader = new FileReader();
            reader.onload = (ev) => {
                if (ev.target) {
                    const bstr = ev.target.result;
                    const wb = XLSX.read(bstr, { type: "binary" });
                    const wsname = wb.SheetNames[0];
                    const ws = wb.Sheets[wsname];
                    this.entitiesFile = XLSX.utils.sheet_to_json(ws);
                }
            };
            reader.readAsBinaryString(selectedFIle);
        },
        handleImage(e) {
            const selectedImage = e.target.files[0];
            const reader = new FileReader();
            reader.onload = (ev) => {
                if (ev.target) {
                    this.store.getters.currentSettings.clientLogo = ev.target.result;
                }
            };
            reader.readAsDataURL(selectedImage);
        },
        submitData() {            
            buttonHandle.handleWaitingButton(this.submitButton);
            let settings = this.store.getters.currentSettings;
            if (this.groupReportFile && this.groupReportFile !== "") {
                settings.groupReportFile = this.groupReportFile;
            }
            settingsApi.updateSettings(settings).then(() => {
                if (this.entitiesFile) {
                    entitiesApi.uploadOrganization({ companies: this.entitiesFile }).then(() => {
                        buttonHandle.handleValidatedButton(this.submitButton);
                    });
                } else {
                    buttonHandle.handleValidatedButton(this.submitButton);
                }
            });
        },
    },
    setup() {
        const store = useStore();
        const submitButton = ref<HTMLElement | null>(null);
        const { t } = useI18n();
        setCurrentPageBreadcrumbs(t("pages.settings.title"), []);
        return { store, submitButton, t };
    },
    watch: {
        "$i18n.locale": function () {
            setCurrentPageBreadcrumbs(this.t("pages.settings.title"), []);
        },
    },
});
