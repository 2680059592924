<template>
    <div class="row">
        <div class="col-12 mb-3">
            <div class="card mb-5 mb-xl-10">
                <!--begin::Card header-->
                <div class="card-header border-0">
                    <!--begin::Card title-->
                    <div class="card-title m-0">
                        <h3 class="fw-bolder m-0">{{ $t("pages.settings.title") }}</h3>
                    </div>
                    <!--end::Card title-->
                </div>
                <!--begin::Card header-->

                <!--begin::Content-->
                <div id="kt_account_profile_details" class="collapse show">
                    <!--begin::Form-->
                    <Form id="kt_account_profile_details_form" class="form" novalidate="novalidate" @submit="submitData">
                        <!--begin::Card body-->
                        <div class="card-body border-top p-9">
                            <!--begin::Input group-->
                            <div class="row mb-6">
                                <!--begin::Label-->
                                <label class="col-lg-4 col-form-label fw-bold fs-6">{{ $t("pages.settings.avatar") }}</label>
                                <!--end::Label-->

                                <!--begin::Col-->
                                <div class="col-lg-8">
                                    <!--begin::Image input-->
                                    <div class="image-input image-input-outline" data-kt-image-input="true" style="background-image: url('/media/avatars/blank.png')">
                                        <!--begin::Preview existing avatar-->
                                        <div
                                            v-if="this.$store.getters.currentSettings.clientLogo"
                                            class="image-input-wrapper w-125px h-125px"
                                            :style="`background-image:url('` + this.$store.getters.currentSettings.clientLogo + `')`"
                                        ></div>
                                        <div v-else class="image-input-wrapper w-125px h-125px" :style="`background-image: url('/media/avatars/blank.png')`"></div>
                                        <!--end::Preview existing avatar-->

                                        <!--begin::Label-->
                                        <label
                                            class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                            data-kt-image-input-action="change"
                                            data-bs-toggle="tooltip"
                                            title="Change avatar"
                                        >
                                            <i class="bi bi-pencil-fill fs-7"></i>

                                            <!--begin::Inputs-->
                                            <input @change="handleImage" type="file" name="avatar" accept=".png, .jpg, .jpeg, .svg" />
                                            <input type="hidden" name="avatar_remove" />
                                            <!--end::Inputs-->
                                        </label>
                                        <!--end::Label-->
                                    </div>
                                    <!--end::Image input-->

                                    <!--begin::Hint-->
                                    <div class="form-text">{{ $t("pages.settings.allowedFiles") }}</div>
                                    <!--end::Hint-->
                                </div>
                                <!--end::Col-->
                            </div>
                            <!--end::Input group-->

                            <!--begin::Input group-->
                            <div class="row mb-6">
                                <!--begin::Label-->
                                <label class="col-lg-4 col-form-label required fw-bold fs-6">{{ $t("pages.settings.name") }}</label>
                                <!--end::Label-->

                                <!--begin::Col-->
                                <div class="col-lg-8">
                                    <!--begin::Row-->
                                    <div class="row">
                                        <!--begin::Col-->
                                        <div class="col-lg-12 fv-row">
                                            <Field
                                                type="text"
                                                name="clientName"
                                                class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                placeholder="Nom client"
                                                v-model="this.$store.getters.currentSettings.clientName"
                                            />
                                            <div class="fv-plugins-message-container">
                                                <div class="fv-help-block">
                                                    <ErrorMessage name="fname" />
                                                </div>
                                            </div>
                                        </div>
                                        <!--end::Col-->
                                    </div>
                                    <!--end::Row-->
                                </div>
                                <!--end::Col-->
                            </div>
                            <div class="row mb-6">
                                <!--begin::Label-->
                                <label class="col-lg-4 col-form-label required fw-bold fs-6">{{ $t("pages.settings.signature") }}</label>
                                <!--end::Label-->

                                <!--begin::Col-->
                                <div class="col-lg-8">
                                    <!--begin::Row-->
                                    <div class="row">
                                        <!--begin::Col-->
                                        <div class="col-lg-12 fv-row">
                                            <Field
                                                type="text"
                                                class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                placeholder="Signature Client"
                                                name="clientSignature"
                                                v-model="this.$store.getters.currentSettings.clientSignature"
                                            />
                                        </div>
                                        <!--end::Col-->
                                    </div>
                                    <!--end::Row-->
                                </div>
                                <!--end::Col-->
                            </div>
                            <div class="row mb-6">
                                <!--begin::Label-->
                                <label class="col-lg-4 col-form-label required fw-bold fs-6">{{ $t("pages.dashboard.entities") }}</label>
                                <!--end::Label-->

                                <!--begin::Col-->
                                <div class="col-lg-8">
                                    <!--begin::Row-->
                                    <div class="row">
                                        <!--begin::Col-->
                                        <div class="col-lg-12 fv-row">
                                            <input
                                                @change="this.handleFile"
                                                type="file"
                                                class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                placeholder="Struture organisationnelle"
                                                name="entitiesFile"
                                            />
                                        </div>
                                        <!--end::Col-->
                                    </div>
                                    <!--end::Row-->
                                </div>
                                <!--end::Col-->
                            </div>
                            <div class="row mb-6">
                                <!--begin::Label-->
                                <label class="col-lg-4 col-form-label required fw-bold fs-6">{{ $t("pages.settings.group") }}</label>
                                <!--end::Label-->

                                <!--begin::Col-->
                                <div class="col-lg-8">
                                    <!--begin::Row-->
                                    <div class="row">
                                        <!--begin::Col-->
                                        <div class="col-lg-12 fv-row">
                                            <input
                                                @change="this.handleFileGroup"
                                                type="file"
                                                class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                placeholder="Rapport groupe"
                                                name="fileReport"
                                            />
                                        </div>
                                        <!--end::Col-->
                                    </div>
                                    <!--end::Row-->
                                </div>
                                <!--end::Col-->
                            </div>
                            <div class="row mb-6">
                                <!--begin::Label-->
                                <label class="col-lg-4 col-form-label fw-bold fs-6">Participation Groupe</label>
                                <!--end::Label-->
                                <!--begin::Col-->
                                <div class="col-lg-8">
                                    <!--begin::Row-->
                                    <div class="row">
                                        <!--begin::Col-->
                                        <div class="col-lg-12 fv-row">
                                            <Field
                                                type="text"
                                                class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                placeholder="Participation groupe"
                                                name="groupParticipationLink"
                                                v-model="this.$store.getters.currentSettings.groupParticipationLink"
                                            />
                                        </div>
                                        <!--end::Col-->
                                    </div>
                                    <!--end::Row-->
                                </div>
                                <!--end::Col-->
                            </div>
                            <!--end::Input group-->

                            <!--begin::Input group-->
                            <div class="row mb-6">
                                <!--begin::Label-->
                                <label class="col-lg-4 col-form-label fw-bold fs-6">{{ $t("pages.settings.languages") }}</label>
                                <!--end::Label-->
                                <!--begin::Col-->
                                <div class="col-lg-8 fv-row">
                                    <!--begin::Options-->
                                    <div class="d-flex align-items-center mt-3">
                                        <!--begin::Option-->
                                        <label class="form-check form-check-inline form-check-solid me-5">
                                            <input class="form-check-input" name="communication[]" v-model="this.$store.getters.currentSettings.fr" type="checkbox" />
                                            <span class="fw-bold ps-2 fs-6"> {{ $t("pages.settings.fr") }} </span>
                                        </label>
                                        <!--end::Option-->

                                        <!--begin::Option-->
                                        <label class="form-check form-check-inline form-check-solid">
                                            <input class="form-check-input" name="communication[]" v-model="this.$store.getters.currentSettings.en" type="checkbox" />
                                            <span class="fw-bold ps-2 fs-6"> {{ $t("pages.settings.en") }} </span>
                                        </label>
                                        <!--end::Option-->
                                    </div>
                                    <!--end::Options-->
                                </div>
                                <!--end::Col-->
                            </div>
                            <!--end::Input group-->

                            <!--begin::Input group-->
                            <div class="row mb-0">
                                <!--begin::Label-->
                                <label class="col-lg-4 col-form-label fw-bold fs-6">{{ $t("pages.settings.activePlatform") }}</label>
                                <!--begin::Label-->

                                <!--begin::Label-->
                                <div class="col-lg-8 d-flex align-items-center">
                                    <div class="form-check form-check-solid form-switch fv-row">
                                        <input
                                            class="form-check-input w-45px h-30px"
                                            v-model="this.$store.getters.currentSettings.is_activated"
                                            type="checkbox"
                                            id="allowmarketing"
                                        />
                                        <label class="form-check-label" for="allowmarketing"></label>
                                    </div>
                                </div>
                                <!--begin::Label-->
                            </div>
                            <!--end::Input group-->
                        </div>
                        <!--end::Card body-->

                        <!--begin::Actions-->
                        <div class="card-footer d-flex justify-content-end py-6 px-9">
                            <button type="reset" class="btn btn-white btn-active-light-primary me-2">{{ $t("general.cancel") }}</button>

                            <button type="submit" id="kt_account_profile_details_submit" ref="submitButton" class="btn btn-primary">
                                <span class="indicator-label"> {{ $t("general.save") }} </span>
                                <span class="indicator-progress">
                                    {{ $t("general.pleaseWait") }}
                                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                                <span class="indicator-validate">
                                    {{ $t("general.done") }}
                                    <span class="fas fa-check f-5 ms-3"></span>
                                </span>
                            </button>
                        </div>
                        <!--end::Actions-->
                    </Form>
                    <!--end::Form-->
                </div>
                <!--end::Content-->
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import settingsApi from "@/core/services/Settings";
import entitiesApi from "@/core/services/Entities";
import { useStore } from "vuex";
import XLSX from "xlsx";
import buttonHandle from "@/shared.js";
import { useI18n } from "vue-i18n";

export default defineComponent({
    name: "administration-settings",
    components: {
        ErrorMessage,
        Field,
        Form,
    },
    data: function () {
        return {
            groupReportFile: "" as string | ArrayBuffer,
            entitiesFile: [],
            avatar: "/media/avatars/blank.png",
        };
    },
    methods: {
        handleFileGroup(e) {
            const selectedFile = e.target.files[0];
            const reader = new FileReader();
            reader.onload = (ev) => {
                if (ev.target && ev.target.result) {
                    this.groupReportFile = ev.target.result;
                }
            };
            reader.readAsDataURL(selectedFile);
        },
        handleFile(e) {
            const selectedFIle = e.target.files[0];
            const reader = new FileReader();
            reader.onload = (ev) => {
                if (ev.target) {
                    const bstr = ev.target.result;
                    const wb = XLSX.read(bstr, { type: "binary" });
                    const wsname = wb.SheetNames[0];
                    const ws = wb.Sheets[wsname];
                    this.entitiesFile = XLSX.utils.sheet_to_json(ws);
                }
            };
            reader.readAsBinaryString(selectedFIle);
        },
        handleImage(e) {
            const selectedImage = e.target.files[0];
            const reader = new FileReader();
            reader.onload = (ev) => {
                if (ev.target) {
                    this.store.getters.currentSettings.clientLogo = ev.target.result;
                }
            };
            reader.readAsDataURL(selectedImage);
        },
        submitData() {            
            buttonHandle.handleWaitingButton(this.submitButton);
            let settings = this.store.getters.currentSettings;
            if (this.groupReportFile && this.groupReportFile !== "") {
                settings.groupReportFile = this.groupReportFile;
            }
            settingsApi.updateSettings(settings).then(() => {
                if (this.entitiesFile) {
                    entitiesApi.uploadOrganization({ companies: this.entitiesFile }).then(() => {
                        buttonHandle.handleValidatedButton(this.submitButton);
                    });
                } else {
                    buttonHandle.handleValidatedButton(this.submitButton);
                }
            });
        },
    },
    setup() {
        const store = useStore();
        const submitButton = ref<HTMLElement | null>(null);
        const { t } = useI18n();
        setCurrentPageBreadcrumbs(t("pages.settings.title"), []);
        return { store, submitButton, t };
    },
    watch: {
        "$i18n.locale": function () {
            setCurrentPageBreadcrumbs(this.t("pages.settings.title"), []);
        },
    },
});
</script>
